import {
  IPlatformData,
  withCompController,
} from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  IImageXControllerProps,
  IImageXMapperProps,
  IImageXProps,
  IImageXStateValues,
} from '../ImageX.types';

const useComponentProps = ({
  mapperProps,
  stateValues,
}: IPlatformData<
  IImageXMapperProps,
  IImageXControllerProps,
  IImageXStateValues
>): IImageXProps => {
  const {
    compId,
    language,
    pagesMap,
    mainPageId,
    fullNameCompType,
    shouldReportAnalyticsElementsClicks,
    ...restMapperProps
  } = mapperProps;

  const reportBiOnClick: IImageXControllerProps['reportBiOnClick'] = event => {
    const { imageInfo, link } = restMapperProps;
    const { reportBi, pageId } = stateValues;

    tryReportAnalyticsClicksBi(reportBi, {
      link,
      language,
      shouldReportAnalyticsElementsClicks,
      elementType: fullNameCompType,
      elementTitle: imageInfo.imageData.name,
      elementGroup: AnalyticsClicksGroups.Image,
      details: { uri: imageInfo.imageData.uri },
      element_id: compId ?? event.currentTarget.id,
      pagesMetadata: { pagesMap, pageId, mainPageId },
    });
  };

  return {
    ...restMapperProps,
    reportBiOnClick,
  };
};

export default withCompController(useComponentProps);
